import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../visa-list/visa-list.css'
import { Button, Alert } from 'react-bootstrap';

function VisaLit() {
    const location = useLocation();
    const [vdata, setData] = useState([]);
    const [formData, setformDataData] = useState([]);

    useEffect(() => {
        if (location.state && location.state.vdata) {
            setData(location.state.vdata);
            setformDataData(location.state.formData);
        } else {
            console.log("No data available");
        }
    }, [location.state]);

    const handleClick = (value) => {
        const encodedData = btoa(value);
        const jsonString = JSON.stringify(formData);
        const encodedformData = btoa(jsonString);
        window.open(`/#/visa-verification/?data=${encodedData}&other=${encodedformData}`, '_blank');
    };

    // Get today's date and add 4 days
    const today = new Date();
    today.setDate(today.getDate() + 4);
    const deliveryDate = today.toDateString();

    return (
        <div className="flex flex-col max-w-screen-xl mt-4 m-4">
            {vdata && vdata.length > 0 ? (
                vdata.map((visa, index) => (
                    <div className="visa-card-sit" key={index}>
                        {/* <Alert variant="warning" className="custom-alert">
                            Your visa will not come in time before your departure date. Your visa will be delivered on <strong>{deliveryDate}</strong>
                        </Alert> */}

                        <div className="card-content">
                            <div className="visa-header">
                                <h5>{visa.about}</h5>
                            </div>

                            <div className="visa-details">
                                <div className="visa-row">
                                    <span>Entry</span>
                                    <span>{visa.entry}</span>
                                </div>
                                <div className="visa-row">
                                    <span>Validity</span>
                                    <span>{visa.validity}</span>
                                </div>
                                <div className="visa-row">
                                    <span>Duration</span>
                                    <span>{visa.duration}</span>
                                </div>
                                <div className="visa-row">
                                    <span>Documents</span>
                                    <span>{visa.documents}</span>
                                </div>
                                <div className="visa-row">
                                    <span>Processing Time</span>
                                    <span>{visa.processing_time}</span>
                                </div>
                            </div>

                            <div className="footer">
                                <span className="price">₹{visa.amount}</span>
                                <Button onClick={() => handleClick(visa.id)} variant="primary" className="select-button">Apply Now</Button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>No visa data available</p>
            )}
        </div>
    );
}

export default VisaLit;
