import React, { useState, useEffect } from 'react';
import '../flight-booking-main/flight-booking-main.css'
import FlightBookingMainLeft from '../flight-booking-main/flight-booking-main-left'
import FlightBookingMainRight from '../flight-booking-main/flight-booking-main-right'
import { useLocation } from 'react-router-dom';


const FlightBookingMain = ({ data,traveltype, onUpdate }) => {

    console.log(data);
    const location = useLocation();
    // const { FlightData, Search_Key, fareid, charges, adult, faredetails } = data || {};
     const FlightData = data[0].flight;
     const Search_Key = data[0].Search_Key;
     const fareid = data[0].fareid;
     const charges = data[0].charges;
     const adult = data[0].adultcount;
     const faredetails =data[0].item ;

     const sum = data.reduce((total, item) => {
        return  total + (item.item.FareDetails[0].Total_Amount + Number(item.charges) || 0); // Add the amount if it exists, otherwise add 0
    }, 0);


    const AdultAmount = Number(sum) * (data[0].adultcount.adult);
    const childAmount = Number(sum) * (data[0].adultcount.child);
    const infantAmount = (((Number(sum)) * 10) / (100));
    const finalinfantAmount = (((Number(sum)) * 10) / (100) * Number(data[0].adultcount.infant));
    const bookingamount = Number(AdultAmount) + Number(childAmount) + Number(finalinfantAmount) ;

    return (
        <section className="flight-booking">
            <div className="contain">
                <div className="row">
                    <FlightBookingMainLeft data={data} traveltype={traveltype} bookingamount={bookingamount} />
                    <FlightBookingMainRight data={data}    onUpdate={(updatedItem)=>{
                                         onUpdate({})
                                                             
                                       }
                                       // console.log(JSON.stringify(updatedItem))
                                     }/>
                </div>
            </div>
        </section>
    );
};
// aa

export default FlightBookingMain;